import { all, takeLatest } from "redux-saga/effects";
import { exceptionHandler } from "../exception-handler";

import * as actionTypes from "./types";
import * as workerSagas from "./workerSagas";


export function* getIndustriesRequestWatcher() {
  yield takeLatest(
    actionTypes.GET_INDUSTRIES_REQUEST,
    exceptionHandler(workerSagas.getIndustries)
  );
}

export function* industriesSaga() {
    yield all([getIndustriesRequestWatcher()]);
}

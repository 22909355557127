import { createAction } from "@reduxjs/toolkit";
import * as actionTypes from "./types";

/**
 * GET ROLES
 */

export const getRolesRequest = createAction( actionTypes.GET_ROLES_REQUEST );
export const getRolesSuccess = createAction( actionTypes.GET_ROLES_SUCCESS );

/**
 * GET PERMISSIONS
 */

export const getPermissionsRequest = createAction( actionTypes.GET_PERMISSIONS_REQUEST );
export const getPermissionsSuccess = createAction( actionTypes.GET_PERMISSIONS_SUCCESS );

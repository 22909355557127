import { createAction } from "@reduxjs/toolkit";
import * as actionTypes from "./types";

/**
 * GET USERS
 */

export const getUsersRequest = createAction(actionTypes.GET_USERS_REQUEST);
export const getUsersSuccess = createAction(actionTypes.GET_USERS_SUCCESS);


/**
 * UPDATE COMPANY USER
 */

export const updateCompanyUserRequest = createAction( actionTypes.UPDATE_COMPANY_USER_REQUEST );
export const updateCompanyUserSuccess = createAction( actionTypes.UPDATE_COMPANY_USER_SUCCESS );


/**
 * DELETE USER FROM COMPANY
 */

export const deleteUserFromCompanyRequest = createAction( actionTypes.DELETE_USER_FROM_COMPANY_REQUEST );
export const deleteUserFromCompanySuccess = createAction( actionTypes.DELETE_USER_FROM_COMPANY_SUCCESS );

import React, { useState, useEffect } from "react";
import { Input, Button } from "antd";
import { useDispatch } from "react-redux";
import { FiMenu } from "react-icons/fi";
import { CgClose } from "react-icons/cg";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";

import { checkIsActive } from "utils";
import { mockUserLogout } from "redux/modules/auth/actions";

import { ReactComponent as NotificationsIcon } from "assets/NotificationsIcon.svg";
import { ReactComponent as UpgradeIcon } from "assets/UpgradeIcon.svg";
import { ReactComponent as SearchIcon } from "assets/SearchIcon.svg";
import { ReactComponent as SettingsIcon } from "assets/SettingsIcon.svg";

import styles from "./styles.module.scss";

const MobileHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? styles.active : null;
  };

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const logoutHandler = () => {
    dispatch(mockUserLogout());
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.menuIconContainer}
        onClick={() => setIsMenuOpen(true)}
      >
        <FiMenu className={styles.menuIcon} />
      </div>
      <div
        className={classNames(styles.menuContainer, {
          [styles.menuContainerToggled]: isMenuOpen,
        })}
      >
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <div className={styles.header}>
            <div className={styles.logo}>Isly.io</div>
            <div
              className={styles.menuIconContainer}
              onClick={() => setIsMenuOpen(false)}
            >
              <CgClose className={styles.closeIcon} />
            </div>
          </div>

          <Link
              className={classNames(
                  styles.menuItem,
                  getMenuItemActive("/dashboard")
              )}
              to="/dashboard"
          >
            Dashboard
          </Link>

          <Link
              className={classNames(
                  styles.menuItem,
                  getMenuItemActive("/organization")
              )}
              to="/organization"
          >
            Organization
          </Link>

          <Link
              className={classNames(
                  styles.menuItem,
                  getMenuItemActive("/products")
              )}
              to="/products"
          >
            Products
          </Link>

          <Link
              className={classNames(
                  styles.menuItem,
                  getMenuItemActive("/security")
              )}
              to="/security"
          >
            Security
          </Link>
          <Link
              className={classNames(
                  styles.menuItem,
                  getMenuItemActive("/billing")
              )}
              to="/billing"
          >
            Billing
          </Link>
          <Link
              className={classNames(
                  styles.menuItem,
                  getMenuItemActive("/settings")
              )}
              to="/settings"
          >
            Settings
          </Link>
        </div>
        <div className={styles.toolbarContainer}>
          <Button icon={<UpgradeIcon />} className={styles.upgradeButton}>
            Upgrade Now
          </Button>
          <div className={styles.actionsContainer}>
            <div onClick={logoutHandler} className={styles.actionButton}>
              <NotificationsIcon />
            </div>
            <Link to="/profile-settings">
              <div className={styles.actionButton}>
                <SettingsIcon />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;

import React, {Component, useState}  from 'react';
import styles from "./styles.module.scss";
import {ReactComponent as DeepensiveLogo} from "../../../assets/DeepensiveLogo.svg";

function LoadingMessage() {
    return (
        <div className={styles.splashScreen}>
            <DeepensiveLogo  />
            <div className={styles.loadingDot}>.</div>
        </div>
    );
}

const SplashScreen = (WrappedComponent) => {
    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                loading: true,
            };
        }

        async componentDidMount() {
            try {
                setTimeout(() => {
                    this.setState({
                        loading: false,
                    });
                }, 1500)
            } catch (err) {
                console.log(err);
                this.setState({
                    loading: false,
                });
            }
        }

        render() {
            // while checking user session, show "loading" message
            if (this.state.loading) return LoadingMessage();

            // otherwise, show the desired route
            return <WrappedComponent {...this.props} />;
        }
    };
}

export default SplashScreen;
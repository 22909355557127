import { call, put } from "redux-saga/effects";

import * as api from "./api";
import * as actions from "./actions";
import {toast} from "sonner";


/**
 * GET USERS
 */
export function* getUsers() {
    const response = yield call(api.getUsers);
    const {
        data: { data },
    } = response;

    yield put(actions.getUsersSuccess(data));
}

/**
 * UPDATE COMPANY USER
 */
export function* updateCompanyUser(action) {
    const response = yield call(api.updateCompanyUser, action.payload);

    yield getUsers();

    const {
        data: { data },
    } = response;

    yield put(actions.updateCompanyUserSuccess(data));
    yield toast.success(response.data.message);
}

/**
 * DELETE USER FROM COMPANY
 */
export function* deleteUserFromCompany(action) {
    const response = yield call(api.deleteUserFromCompany, action.payload);

    yield getUsers();

    const {
        data: { data },
    } = response;

    yield put(actions.deleteUserFromCompanySuccess(data));
    yield toast.success(response.data.message);

}
import * as actionTypes from "./types";
import { ACTION_ERROR } from "../exception-handler/types";

const initialState = {
    loading: false,
    metrics: null,
    company: null,
    policies: null,
    policy: null,
    ipRestrictions: null,
};

export const securityReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SECURITY_METRICS_REQUEST:
        return {
            ...state,
            loading: true,
        }
    case actionTypes.GET_SECURITY_METRICS_SUCCESS:
        return {
            ...state,
            loading: false,
            metrics: action.payload
        }
    case actionTypes.ENFORCE_2FA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ENFORCE_2FA_SUCCESS:
      return {
        ...state,
        loading: false,
        company: action.payload
      };

    case actionTypes.STOP_ENFORCE_2FA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_ENFORCE_2FA_SUCCESS:
      return {
        ...state,
        loading: false,
        company: action.payload
      };

    case actionTypes.ENFORCE_SSO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.ENFORCE_SSO_SUCCESS:
      return {
        ...state,
        loading: false,
        company: action.payload
      };

    case actionTypes.STOP_ENFORCE_SSO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STOP_ENFORCE_SSO_SUCCESS:
      return {
        ...state,
        loading: false,
        company: action.payload
      };

    case actionTypes.GET_ALL_AUTHENTICATION_POLICIES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ALL_AUTHENTICATION_POLICIES_SUCCESS:
      return {
        ...state,
        loading: false,
        policies: action.payload
      };

    case actionTypes.DELETE_AUTHENTICATION_POLICIES_REQUEST:
        return {
            ...state,
            loading: true,
        };

    case actionTypes.DELETE_AUTHENTICATION_POLICIES_SUCCESS:
        return {
            ...state,
            loading: false,
        }

    case actionTypes.GET_AUTHENTICATION_POLICY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_AUTHENTICATION_POLICY_SUCCESS:
      return {
        ...state,
        loading: false,
        policy: action.payload
      };

    case actionTypes.CREATE_AUTHENTICATION_POLICY_REQUEST:
        return {
            ...state,
            loading: true,
        }

    case actionTypes.CREATE_AUTHENTICATION_POLICY_SUCCESS:
        return {
            ...state,
            loading: false,
        }

    case actionTypes.UPDATE_AUTHENTICATION_POLICY_REQUEST:
        return {
            ...state,
            loading: true,
        };
    case actionTypes.UPDATE_AUTHENTICATION_POLICY_SUCCESS:
        return {
            ...state,
            loading: false,
        }

    case actionTypes.DELETE_MEMBER_FROM_AUTHENTICATION_POLICY_REQUEST:
        return {
            ...state,
            loading: true,
        };
    case actionTypes.DELETE_MEMBER_FROM_AUTHENTICATION_POLICY_SUCCESS:
        return {
            ...state,
            loading: false,
        }

    case actionTypes.GET_IP_RESTRICTIONS_REQUEST:
        return {
            ...state,
            loading: true,
        };
    case actionTypes.GET_IP_RESTRICTIONS_SUCCESS:
        return {
            ...state,
            loading: false,
            ipRestrictions: action.payload
        }

    case ACTION_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

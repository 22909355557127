import { createAction } from "@reduxjs/toolkit";
import * as actionTypes from "./types";

/**
 * GET AUTH USER
 */

export const getAuthUserRequest = createAction(
  actionTypes.GET_AUTH_USER_REQUEST
);
export const getAuthUserSuccess = createAction(
  actionTypes.GET_AUTH_USER_SUCCESS
);


/**
 * CHECK TOKEN
 */

export const checkTokenRequest = createAction(actionTypes.CHECK_TOKEN_REQUEST);
export const checkTokenSuccess = createAction(actionTypes.CHECK_TOKEN_SUCCESS);
export const checkTokenFail = createAction(actionTypes.CHECK_TOKEN_FAIL);

export const checkTokenWithParamRequest = createAction(actionTypes.CHECK_TOKEN_WITH_PARAM_REQUEST);
export const checkTokenWithParamSuccess = createAction(actionTypes.CHECK_TOKEN_WITH_PARAM_SUCCESS);
export const checkTokenWithParamFail = createAction(actionTypes.CHECK_TOKEN_WITH_PARAM_FAIL);

export const mockUserLogin = createAction(actionTypes.MOCK_USER_LOGIN);
export const mockUserLogout = createAction(actionTypes.MOCK_USER_LOGOUT);


import axios from "axios";

const { REACT_APP_ISLY_API_URL: URL } = process.env;

/**
 * GET SECURITY METRICS
 */
export const getSecurityMetrics = () => {
    return axios({
        method: "GET",
        url: `${URL}/metrics/security`,
        data: null,
    });
}


/**
 * ACTIVE 2FA
 */

export const enforce2FA = (  password_confirm  ) => {
  return axios({
    method: "POST",
    url: `${URL}/company/two-factor-auth/enforce`,
    data: {
      password_confirm: password_confirm.password
    },
  });
};


/**
 * ACTIVE SSO
 */

export const stopEnforce2FA = (  password_confirm ) => {
  return axios({
    method: "POST",
    url: `${URL}/company/two-factor-auth/stop-enforce`,
    data: {
      password_confirm: password_confirm.password
    },
  });
};


/**
 * ACTIVE SSO
 */

export const enforceSSO = ( password_confirm ) => {
  return axios({
    method: "POST",
    url: `${URL}/company/sso/enforce`,
    data: {
      password_confirm: password_confirm.password
    },
  });
};


/**
 * ACTIVE SSO
 */

export const stopEnforceSSO = ( password_confirm ) => {
  return axios({
    method: "POST",
    url: `${URL}/company/sso/stop-enforce`,
    data: {
      password_confirm: password_confirm.password
    },
  });
};


/**
 * GET AUTHENTICATION POLICIES
 */

export const getAllAuthenticationPolicies = () => {
  return axios({
    method: "GET",
    url: `${URL}/auth-policies`,
    data: null,
  });
};


/**
 * GET AUTHENTICATION POLICY
 */

export const getAuthenticationPolicy = (id) => {
    return axios({
        method: "GET",
        url: `${URL}/auth-policies/${id}`,
        data: null,
    });
}

/**
 * CREATE AUTHENTICATION POLICY
 */

export const createAuthenticationPolicy = (payload) => {
    return axios({
        method: "POST",
        url: `${URL}/auth-policies`,
        data: payload,
    });
}


/**
 * UPDATE AUTHENTICATION POLICY
 */

export const updateAuthenticationPolicy = (payload) => {
    return axios({
        method: "PUT",
        url: `${URL}/auth-policies/${payload.id}`,
        data: payload,
    });
}


/**
 * DELETE AUTHENTICATION POLICIES
 */

export const deleteAuthenticationPolicies = (payload) => {
    return axios({
        method: "DELETE",
        url: `${URL}/auth-policies/${payload.id}`,
        data: null,
    });
}


/**
 * DELETE MEMBER FROM AUTHENTICATION POLICY
 */

export const deleteMemberFromAuthenticationPolicy = (payload) => {
    return axios({
        method: "DELETE",
        url: `${URL}/auth-policies/${payload.id}/remove-users`,
        data: payload,
    });
}


/**
 * GET IP RESTRICTIONS
 */

export const getIpRestrictions = () => {
    return axios({
        method: "GET",
        url: `${URL}/ip-whitelisting`,
        data: null,
    });
}
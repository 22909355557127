import { all, takeLatest } from "redux-saga/effects";
import { exceptionHandler } from "../exception-handler";

import * as actionTypes from "./types";
import * as workerSagas from "./workerSagas";
import {getActiveNotificationChannels} from "./workerSagas";


export function* getCompanyRequestWatcher() {
    yield takeLatest(
        actionTypes.GET_COMPANY_REQUEST,
        exceptionHandler(workerSagas.getCompany)
    );
}

export function* updateCompanyRequestWatcher() {
    yield takeLatest(
        actionTypes.UPDATE_COMPANY_REQUEST,
        exceptionHandler(workerSagas.updateCompany)
    );
}

export function* getApiKeysRequestWatcher() {
    yield takeLatest(
        actionTypes.GET_API_KEYS_REQUEST,
        exceptionHandler(workerSagas.getApiKeys)
    );
}

export function* updateApiKeyRequestWatcher() {
    yield takeLatest(
        actionTypes.UPDATE_API_KEY_REQUEST,
        exceptionHandler(workerSagas.updateApiKey)
    );
}

export function* deleteApiKeyRequestWatcher() {
    yield takeLatest(
        actionTypes.DELETE_API_KEY_REQUEST,
        exceptionHandler(workerSagas.deleteApiKey)
    );
}

export function* createApiKeyRequestWatcher() {
    yield takeLatest(
        actionTypes.CREATE_API_KEY_REQUEST,
        exceptionHandler(workerSagas.createApiKey)
    );
}

export function* getActiveNotificationChannelsRequestWatcher() {
    yield takeLatest(
        actionTypes.GET_ACTIVE_NOTIFICATION_CHANNELS_REQUEST,
        exceptionHandler(workerSagas.getActiveNotificationChannels)
    );
}

export function* updateNotificationChannelRequestWatcher() {
    yield takeLatest(
        actionTypes.UPDATE_NOTIFICATION_CHANNEL_REQUEST,
        exceptionHandler(workerSagas.updateNotificationChannel)
    );
}

export function* deleteNotificationChannelRequestWatcher() {
    yield takeLatest(
        actionTypes.DELETE_NOTIFICATION_CHANNEL_REQUEST,
        exceptionHandler(workerSagas.deleteNotificationChannel)
    );
}

export function* getAllNotificationChannelsInformationRequestWatcher() {
    yield takeLatest(
        actionTypes.GET_ALL_NOTIFICATION_CHANNELS_INFORMATION_REQUEST,
        exceptionHandler(workerSagas.getAllNotificationChannelsInformation)
    );
}

export function* companySaga() {
  yield all([
        getCompanyRequestWatcher(),
        updateCompanyRequestWatcher(),
        getApiKeysRequestWatcher(),
      updateApiKeyRequestWatcher(),
      deleteApiKeyRequestWatcher(),
      createApiKeyRequestWatcher(),
      getActiveNotificationChannelsRequestWatcher(),
      updateNotificationChannelRequestWatcher(),
      deleteNotificationChannelRequestWatcher(),
      getAllNotificationChannelsInformationRequestWatcher(),
  ]);
}

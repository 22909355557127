/**
 * GET SECURITY METRICS
 */
export const GET_SECURITY_METRICS_REQUEST = "GET_SECURITY_METRICS_REQUEST";
export const GET_SECURITY_METRICS_SUCCESS = "GET_SECURITY_METRICS_SUCCESS";


/**
 * ACTIVE 2FA
 */

export const ENFORCE_2FA_REQUEST = "ENFORCE_2FA_REQUEST";
export const ENFORCE_2FA_SUCCESS = "ENFORCE_2FA_SUCCESS";

/**
 * DEACTIVE 2FA
 */

export const STOP_ENFORCE_2FA_REQUEST = "STOP_ENFORCE_2FA_REQUEST";
export const STOP_ENFORCE_2FA_SUCCESS = "STOP_ENFORCE_2FA_SUCCESS";

/**
 * ACTIVE SSO
 */

export const ENFORCE_SSO_REQUEST = "ENFORCE_SSO_REQUEST";
export const ENFORCE_SSO_SUCCESS = "ENFORCE_SSO_SUCCESS";

/**
 * DEACTIVE SSO
 */

export const STOP_ENFORCE_SSO_REQUEST = "STOP_ENFORCE_SSO_REQUEST";
export const STOP_ENFORCE_SSO_SUCCESS = "STOP_ENFORCE_SSO_SUCCESS";


/**
 * GET AUTHENTICATION POLICIES
 */

export const GET_ALL_AUTHENTICATION_POLICIES_REQUEST = "GET_ALL_AUTHENTICATION_POLICIES_REQUEST";
export const GET_ALL_AUTHENTICATION_POLICIES_SUCCESS = "GET_ALL_AUTHENTICATION_POLICIES_SUCCESS";


/**
 * GET AUTHENTICATION POLICY
 */

export const GET_AUTHENTICATION_POLICY_REQUEST = "GET_AUTHENTICATION_POLICY_REQUEST";
export const GET_AUTHENTICATION_POLICY_SUCCESS = "GET_AUTHENTICATION_POLICY_SUCCESS";

/**
 * CREATE AUTHENTICATION POLICY
 */

export const CREATE_AUTHENTICATION_POLICY_REQUEST = "CREATE_AUTHENTICATION_POLICY_REQUEST";
export const CREATE_AUTHENTICATION_POLICY_SUCCESS = "CREATE_AUTHENTICATION_POLICY_SUCCESS";

/**
 * UPDATE AUTHENTICATION POLICY
 */

export const UPDATE_AUTHENTICATION_POLICY_REQUEST = "UPDATE_AUTHENTICATION_POLICY_REQUEST";
export const UPDATE_AUTHENTICATION_POLICY_SUCCESS = "UPDATE_AUTHENTICATION_POLICY_SUCCESS";


/**
 * DELETE AUTHENTICATION POLICIES
 */

export const DELETE_AUTHENTICATION_POLICIES_REQUEST = "DELETE_AUTHENTICATION_POLICIES_REQUEST";
export const DELETE_AUTHENTICATION_POLICIES_SUCCESS = "DELETE_AUTHENTICATION_POLICIES_SUCCESS";

/**
 * DELETE MEMBER FROM AUTHENTICATION POLICY
 */

export const DELETE_MEMBER_FROM_AUTHENTICATION_POLICY_REQUEST = "DELETE_MEMBER_FROM_AUTHENTICATION_POLICY_REQUEST";
export const DELETE_MEMBER_FROM_AUTHENTICATION_POLICY_SUCCESS = "DELETE_MEMBER_FROM_AUTHENTICATION_POLICY_SUCCESS";


/**
 * GET IP RESTRICTIONS
 */

export const GET_IP_RESTRICTIONS_REQUEST = "GET_IP_RESTRICTIONS_REQUEST";
export const GET_IP_RESTRICTIONS_SUCCESS = "GET_IP_RESTRICTIONS_SUCCESS";

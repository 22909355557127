import { all, takeLatest } from "redux-saga/effects";
import { exceptionHandler } from "../exception-handler";

import * as actionTypes from "./types";
import * as workerSagas from "./workerSagas";


/**
 * GET ROLES
 * @returns {Generator<*, void, *>}
 */
export function* getRolesRequestWatcher() {
    yield takeLatest(
        actionTypes.GET_ROLES_REQUEST,
        exceptionHandler(workerSagas.getRoles)
    );
}

/**
 * GET PERMISSIONS
 * @returns {Generator<*, void, *>}
 */
export function* getPermissionsRequestWatcher() {
    yield takeLatest(
        actionTypes.GET_PERMISSIONS_REQUEST,
        exceptionHandler(workerSagas.getPermissions)
    );
}


export function* roleSaga() {
  yield all([
      getRolesRequestWatcher(),
        getPermissionsRequestWatcher(),
  ]);
}

import { call } from "redux-saga/effects";

import * as globalActions from "./actions";
import { store } from "../../store";

import {toast} from "sonner";


const errorHandler = (error) => {
  store.dispatch(globalActions.actionError());

  if (error.response.data.errors){
      for (const key in error.response.data.errors) {
          if (error.response.data.errors.hasOwnProperty(key)) {
              for (const errorMessage of error.response.data.errors[key]) {
                  toast.error(errorMessage);
              }
          }
      }
  } else {
      toast.error(error?.response?.data?.message);
  }
};

export const exceptionHandler = (saga, ...args) =>
  function* (action) {
    try {
      yield call(saga, ...args, action);
    } catch (error) {
      yield call(errorHandler, ...args, error);
    }
  };

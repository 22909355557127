import { all, takeLatest } from "redux-saga/effects";
import { exceptionHandler } from "../exception-handler";

import * as actionTypes from "./types";
import * as workerSagas from "./workerSagas";


/**
 * GET USERS
 * @returns {Generator<*, void, *>}
 */
export function* getUsersRequestWatcher() {
    yield takeLatest(
        actionTypes.GET_USERS_REQUEST,
        exceptionHandler(workerSagas.getUsers)
    );
}

/**
 * UPDATE COMPANY USER
 * @returns {Generator<*, void, *>}
 */
export function* updateCompanyUserRequestWatcher() {
    yield takeLatest(
        actionTypes.UPDATE_COMPANY_USER_REQUEST,
        exceptionHandler(workerSagas.updateCompanyUser)
    );
}

/**
 * DELETE USER FROM COMPANY
 * @returns {Generator<*, void, *>}
 */
export function* deleteUserFromCompanyRequestWatcher() {
    yield takeLatest(
        actionTypes.DELETE_USER_FROM_COMPANY_REQUEST,
        exceptionHandler(workerSagas.deleteUserFromCompany)
    );
}


export function* organizationSaga() {
  yield all([
      getUsersRequestWatcher(),
      updateCompanyUserRequestWatcher(),
      deleteUserFromCompanyRequestWatcher(),
  ]);
}

import { call, put } from "redux-saga/effects";
import {toast} from "sonner";

import * as api from "./api";
import * as actions from "./actions";
import {getCompany} from "../company/api";
import {getCompanySuccess} from "../company/actions";

/**
 * GET SECURITY METRICS
 */
export function* getSecurityMetrics() {
    const response = yield call(api.getSecurityMetrics);
    const {
        data: { data },
    } = response;

    yield put(actions.getSecurityMetricsSuccess(data));
}


/**
 * Enforce 2FA
 */

export function* enforce2FA(action) {
  const {
    payload: { values },
  } = action;
  const response = yield call(api.enforce2FA, values);
  const {
    data: { data },
  } = response;

  yield put(actions.enforce2FASuccess(data));
  yield getCompany();
  yield put(getCompanySuccess(data));
  yield toast.success(response.data.message);
}

/**
 * Stop Enforce 2FA
 */

export function* stopEnforce2FA(action) {
  const {
    payload: { values },
  } = action;
  const response = yield call(api.stopEnforce2FA, values);
  const {
    data: { data },
  } = response;

  yield put(actions.stopEnforce2FASuccess(data));
  yield getCompany();
  yield put(getCompanySuccess(data));
  yield toast.success(response.data.message);
}

/**
 * Enforce SSO
 */

export function* enforceSSO(action) {
  const {
    payload: { values },
  } = action;
  const response = yield call(api.enforceSSO, values);
  const {
    data: { data },
  } = response;

  yield put(actions.enforceSSOSuccess(data));
  yield getCompany();
  yield put(getCompanySuccess(data));
  yield toast.success(response.data.message);
}

/**
 * Stop Enforce SSO
 */

export function* stopEnforceSSO(action) {
  const {
    payload: { values },
  } = action;
  const response = yield call(api.stopEnforceSSO, values);
  const {
    data: { data },
  } = response;

  yield put(actions.stopEnforceSSOSuccess(data));
  yield getCompany();
  yield put(getCompanySuccess(data));
  yield toast.success(response.data.message);
}


/**
 * GET AUTHENTICATION POLICIES
 */

export function* getAllAuthenticationPolicies() {
  const response = yield call(api.getAllAuthenticationPolicies);
  const {
    data: { data },
  } = response;

  yield put(actions.getAllAuthenticationPoliciesSuccess(data));
}


/**
 * GET AUTHENTICATION POLICY
 */

export function* getAuthenticationPolicy(action) {
    const response = yield call(api.getAuthenticationPolicy, action.payload);
    const {
        data: { data },
    } = response;
    yield put(actions.getAuthenticationPolicySuccess(data));
}


/**
 * CREATE AUTHENTICATION POLICY
 */

export function* createAuthenticationPolicy(action) {
    const response = yield call(api.createAuthenticationPolicy, action.payload);
    const {
        data: { data },
    } = response;

    yield put(actions.createAuthenticationPolicySuccess(data));
    yield toast.success(response.data.message);
    yield window.location.replace("/security/authentication-policies");
}


/**
 * UPDATE AUTHENTICATION POLICY
 */

export function* updateAuthenticationPolicy(action) {
    const response = yield call(api.updateAuthenticationPolicy, action.payload);
    const {
        data: { data },
    } = response;

    yield put(actions.updateAuthenticationPolicySuccess(data));
    yield toast.success(response.data.message);
    yield window.location.replace("/security/authentication-policies");


}

/**
 * DELETE AUTHENTICATION POLICIES
 */

export function* deleteAuthenticationPolicies(action) {
    const response = yield call(api.deleteAuthenticationPolicies, action.payload);

    yield getAllAuthenticationPolicies();

    const {
        data: { data },
    } = response;

    yield put(actions.deleteAuthenticationPoliciesSuccess(data));
    yield toast.success(response.data.message);
}

/**
 * DELETE MEMBER FROM AUTHENTICATION POLICY
 */

export function* deleteMemberFromAuthenticationPolicy(action) {
    const response = yield call(api.deleteMemberFromAuthenticationPolicy, action.payload);

    const {
        data: { data },
    } = response;

    yield put(actions.deleteMemberFromAuthenticationPolicySuccess(data));
    yield toast.success(response.data.message);
    yield window.location.replace("/security/authentication-policies");

}


/**
 * GET IP RESTRICTIONS
 */

export function* getIpRestrictions() {
    const response = yield call(api.getIpRestrictions);
    const {
        data: { data },
    } = response;

    yield put(actions.getIpRestrictionsSuccess(data));
}
import React, { useEffect } from "react";
import {Switch, Route, useLocation} from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import AppRoutes from "./AppRoutes";
import { IslyLayout } from "_isly";
import {checkTokenRequest, checkTokenWithParamRequest} from "redux/modules/auth/actions";
import querystring from "query-string";
const TempLogoutPage = () => <div>TEMP Logout PAGE</div>;

const MainRouter = () => {
  const dispatch = useDispatch();

  const { token, isLoggedIn } = useSelector(
    (state) => ({ token: state.auth.token, isLoggedIn: state.auth.isLoggedIn }),
    shallowEqual
  );

  const location = useLocation();
  const parsedSearch = querystring.parse(location.search);

  useEffect(() => {
    if (token != null) {
      dispatch(checkTokenRequest());
    }else if (parsedSearch.authToken) {
      dispatch(checkTokenWithParamRequest(parsedSearch.authToken));
    }else{
      window.location = "https://deepensive.com"
    }

  }, []);

  const isAuthorized = token != null && isLoggedIn === true;

  return (
    <Switch>
      {!isAuthorized ? (
        "loading"
      ) : (
          <IslyLayout>
            <AppRoutes />
          </IslyLayout>
      )}
      <Route path="/logout" component={TempLogoutPage} />

    </Switch>
  );
};

export default MainRouter;

import { call, put } from "redux-saga/effects";
import {toast} from "sonner";

import * as api from "./api";
import * as actions from "./actions";

/**
 * GET INDUSTRIES
 */

export function* getIndustries() {
    const response = yield call(api.getIndustries);
    const {
        data: { data },
    } = response;

    yield put(actions.getIndustriesSuccess(data));
}
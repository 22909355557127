import * as actionTypes from "./types";
import { ACTION_ERROR } from "../exception-handler/types";

const initialState = {
  loading: false,
  company: null,
  apiKeys: null,
    notificationChannelResults: [],
    allNotificationChannels: [],
};

export const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COMPANY_REQUEST:
        return {
            ...state,
            loading: true,
      };
    case actionTypes.GET_COMPANY_SUCCESS:
        return {
            ...state,
            loading: false,
            company: action.payload,
      };

    case actionTypes.GET_API_KEYS_REQUEST:
        return {
            ...state,
            loading: true,
        };
    case actionTypes.GET_API_KEYS_SUCCESS:
        return {
              ...state,
              loading: false,
              apiKeys: action.payload,
        };

    case actionTypes.UPDATE_API_KEY_REQUEST:
        return {
            ...state,
            loading: true,
        };
    case actionTypes.UPDATE_API_KEY_SUCCESS:
        return {
            ...state,
            loading: false,
            apiKeys: action.payload,
        };

    case actionTypes.DELETE_API_KEY_REQUEST:
        return {
            ...state,
            loading: true,
        }
    case actionTypes.DELETE_API_KEY_SUCCESS:
        return {
            ...state,
            loading: false,
        }

    case actionTypes.CREATE_API_KEY_REQUEST:
        return {
            ...state,
            loading: true,
        }
    case actionTypes.CREATE_API_KEY_SUCCESS:
        return {
            ...state,
            loading: false,
            apiKeys: action.payload,
        }

    case actionTypes.GET_ACTIVE_NOTIFICATION_CHANNELS_REQUEST:
        return {
            ...state,
            loading: true,
        }
    case actionTypes.GET_ACTIVE_NOTIFICATION_CHANNELS_SUCCESS:
        return {
            ...state,
            loading: false,
            notificationChannelResults: action.payload,
        }

    case actionTypes.UPDATE_NOTIFICATION_CHANNEL_REQUEST:
        return {
            ...state,
            loading: true,
        }
    case actionTypes.UPDATE_NOTIFICATION_CHANNEL_SUCCESS:
        return {
            ...state,
            loading: false,
            notificationChannelResults: action.payload,
        }

    case actionTypes.DELETE_NOTIFICATION_CHANNEL_REQUEST:
        return {
            ...state,
            loading: true,
        }
    case actionTypes.DELETE_NOTIFICATION_CHANNEL_SUCCESS:
        return {
            ...state,
            loading: false,
        }

    case actionTypes.GET_ALL_NOTIFICATION_CHANNELS_INFORMATION_REQUEST:
        return {
            ...state,
            loading: true,
        }

    case actionTypes.GET_ALL_NOTIFICATION_CHANNELS_INFORMATION_SUCCESS:
        return {
            ...state,
            loading: false,
            allNotificationChannels: action.payload,
        }

    case ACTION_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

/**
 *  GET USERS
 */

export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";


/**
 * UPDATE COMPANY USER
 */

export const UPDATE_COMPANY_USER_REQUEST = "UPDATE_COMPANY_USER_REQUEST";
export const UPDATE_COMPANY_USER_SUCCESS = "UPDATE_COMPANY_USER_SUCCESS";


/**
 * DELETE USER FROM COMPANY
 */

export const DELETE_USER_FROM_COMPANY_REQUEST = "DELETE_USER_FROM_COMPANY_REQUEST";
export const DELETE_USER_FROM_COMPANY_SUCCESS = "DELETE_USER_FROM_COMPANY_SUCCESS";
import { createAction } from "@reduxjs/toolkit";
import * as actionTypes from "./types";

/**
 * GET SECURITY METRICS
 */

export const getSecurityMetricsRequest = createAction(actionTypes.GET_SECURITY_METRICS_REQUEST);
export const getSecurityMetricsSuccess = createAction(actionTypes.GET_SECURITY_METRICS_SUCCESS);



/**
 * ACTIVE 2FA
 */

export const enforce2FARequest = createAction(actionTypes.ENFORCE_2FA_REQUEST);
export const enforce2FASuccess = createAction(actionTypes.ENFORCE_2FA_SUCCESS);


/**
 * DEACTIVE 2FA
 */

export const stopEnforce2FARequest = createAction(actionTypes.STOP_ENFORCE_2FA_REQUEST);
export const stopEnforce2FASuccess = createAction(actionTypes.STOP_ENFORCE_2FA_SUCCESS);


/**
 * ACTIVE SSO
 */

export const enforceSSORequest = createAction(actionTypes.ENFORCE_SSO_REQUEST);
export const enforceSSOSuccess = createAction(actionTypes.ENFORCE_SSO_SUCCESS);

/**
 * DEACTIVE SSO
 */

export const stopEnforceSSORequest = createAction(actionTypes.STOP_ENFORCE_SSO_REQUEST);
export const stopEnforceSSOSuccess = createAction(actionTypes.STOP_ENFORCE_SSO_SUCCESS);


/**
 * GET AUTHENTICATION POLICIES
 */

export const getAllAuthenticationPoliciesRequest = createAction(actionTypes.GET_ALL_AUTHENTICATION_POLICIES_REQUEST);
export const getAllAuthenticationPoliciesSuccess = createAction(actionTypes.GET_ALL_AUTHENTICATION_POLICIES_SUCCESS);

/**
 * GET AUTHENTICATION POLICY
 */

export const getAuthenticationPolicyRequest = createAction(actionTypes.GET_AUTHENTICATION_POLICY_REQUEST);
export const getAuthenticationPolicySuccess = createAction(actionTypes.GET_AUTHENTICATION_POLICY_SUCCESS);

/**
 * CREATE AUTHENTICATION POLICY
 */

export const createAuthenticationPolicyRequest = createAction(actionTypes.CREATE_AUTHENTICATION_POLICY_REQUEST);
export const createAuthenticationPolicySuccess = createAction(actionTypes.CREATE_AUTHENTICATION_POLICY_SUCCESS);


/**
 * UPDATE AUTHENTICATION POLICY
 */

export const updateAuthenticationPolicyRequest = createAction(actionTypes.UPDATE_AUTHENTICATION_POLICY_REQUEST);
export const updateAuthenticationPolicySuccess = createAction(actionTypes.UPDATE_AUTHENTICATION_POLICY_SUCCESS);


/**
 * DELETE AUTHENTICATION POLICIES
 */

export const deleteAuthenticationPoliciesRequest = createAction(actionTypes.DELETE_AUTHENTICATION_POLICIES_REQUEST);
export const deleteAuthenticationPoliciesSuccess = createAction(actionTypes.DELETE_AUTHENTICATION_POLICIES_SUCCESS);


/**
 * DELETE MEMBER FROM AUTHENTICATION POLICY
 */

export const deleteMemberFromAuthenticationPolicyRequest = createAction(actionTypes.DELETE_MEMBER_FROM_AUTHENTICATION_POLICY_REQUEST);
export const deleteMemberFromAuthenticationPolicySuccess = createAction(actionTypes.DELETE_MEMBER_FROM_AUTHENTICATION_POLICY_SUCCESS);


/**
 *  GET IP RESTRICTIONS
 */

export const getIpRestrictionsRequest = createAction(actionTypes.GET_IP_RESTRICTIONS_REQUEST);
export const getIpRestrictionsSuccess = createAction(actionTypes.GET_IP_RESTRICTIONS_SUCCESS);

import React from "react";
import ReactDOM from "react-dom/client";

import App from "./app/App";
import { store, persistor } from "./redux/store";

import "./index.scss";
import "./themes/dark.less";
import "./themes/default.less";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App {...{ store, persistor }} />);

import React, { useEffect } from "react";
import {Card, Dropdown} from "antd";
import styles from "./styles.module.scss";
import { ReactComponent as HelpIcon } from "assets/HelpIcon.svg";
import {Link} from "react-router-dom";

import { ReactComponent as ArrowUpRight } from "assets/ArrowUpRight.svg";





const HelpButton = () => {

    const menu = () => {
        return (
            <>
                <Card className={styles.HelpButtonCard}>
                    <span>HELP</span>
                    <div className={styles.menuContainer}>
                        <Link to="#" className={styles.menuItem}>
                            <div className={styles.menuInfo}>
                                <div>Admin Documentation</div>
                            </div>
                        </Link>
                        <Link to="#" className={styles.menuItem}>
                            <div className={styles.menuInfo}>
                                <div>Contact Support</div>
                            </div>
                        </Link>
                        <Link to="#" className={styles.menuItem}>
                            <div className={styles.menuInfo}>
                                <div>Give Feedback</div>
                            </div>
                        </Link>
                    </div>

                </Card>
            </>
        );
    };

    return (
        <Dropdown overlay={menu()} trigger={['click']}>
            <a onClick={e => e.preventDefault()}>
                <HelpIcon className={styles.HelpIcon}  />
            </a>
        </Dropdown>
    );

};

export default HelpButton;

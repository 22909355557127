import axios from "axios";
import { store } from "redux/store";

const { REACT_APP_ISLY_API_URL: URL } = process.env;

/**
 * GET AUTH USER
 */

export const getAuthUser = () => {
  const { token } = store.getState().auth;
  return axios({
    method: "GET",
    url: `${URL}/authenticatedUser`,
    headers: { Authorization: `Bearer ${token}` },
    data: null,
  });
};


/**
 * CHECK TOKEN
 */

export const checkToken = () => {
  const { token } = store.getState().auth;
  return axios({
    method: "GET",
    url: `${URL}/authenticatedUser`,
    headers: { Authorization: `Bearer ${token}` },
    data: null,
  });
};

/**
 * CHECK TOKEN with Param
 */

export const checkTokenWithParams = (authToken) => {
  return axios({
    method: "GET",
    url: `${URL}/authenticatedUser`,
    headers: { Authorization: `Bearer ${authToken}` },
    data: null,
  });
};



import axios from "axios";

const { REACT_APP_ISLY_API_URL: URL } = process.env;

/**
 * GET USERS
 */

export const getUsers = () => {
    return axios({
        method: "GET",
        url: `${URL}/users`,
        data: null,
    });
}

/**
 * UPDATE COMPANY USER
 * @param {Object} values
 */

export const updateCompanyUser = (values) => {
    return axios({
        method: "PUT",
        url: `${URL}/users/${values.user_id}`,
        data: values,
    });
}

/**
 * DELETE USER FROM COMPANY
 * @param {Object} values
 */

export const deleteUserFromCompany = (values) => {
    return axios({
        method: "DELETE",
        url: `${URL}/users/${values.user_id}`,
        data: null,
    });
}
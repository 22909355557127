import { all, takeLatest } from "redux-saga/effects";
import { exceptionHandler } from "../exception-handler";

import * as actionTypes from "./types";
import * as workerSagas from "./workerSagas";

/**
 * GET SECURITY METRICS
 */
export function* getSecurityMetricsRequestWatcher() {
    yield takeLatest(
        actionTypes.GET_SECURITY_METRICS_REQUEST,
        exceptionHandler(workerSagas.getSecurityMetrics)
    );
}

/**
 * ENABLE 2FA
 */

export function* enforce2FARequestWatcher() {
    yield takeLatest(
        actionTypes.ENFORCE_2FA_REQUEST,
        exceptionHandler(workerSagas.enforce2FA)
    );
}

/**
 * DISABLE 2FA
 */

export function* stopEnforce2FARequestWatcher() {
    yield takeLatest(
        actionTypes.STOP_ENFORCE_2FA_REQUEST,
        exceptionHandler(workerSagas.stopEnforce2FA)
    );
}

/**
 * ENFORCE SSO
 */
export function* enforceSSORequestWatcher() {
    yield takeLatest(
        actionTypes.ENFORCE_SSO_REQUEST,
        exceptionHandler(workerSagas.enforceSSO)
    );
}

/**
 * STOP ENFORCE SSO
 */
export function* stopEnforceSSORequestWatcher() {
    yield takeLatest(
        actionTypes.STOP_ENFORCE_SSO_REQUEST,
        exceptionHandler(workerSagas.stopEnforceSSO)
    );
}


/**
 * GET ALL AUTHENTICATION POLICIES
 */
export function* getAllAuthenticationPoliciesRequestWatcher() {
    yield takeLatest(
        actionTypes.GET_ALL_AUTHENTICATION_POLICIES_REQUEST,
        exceptionHandler(workerSagas.getAllAuthenticationPolicies)
    );
}

/**
 * GET AUTHENTICATION POLICY
 */
export function* getAuthenticationPolicyRequestWatcher() {
    yield takeLatest(
        actionTypes.GET_AUTHENTICATION_POLICY_REQUEST,
        exceptionHandler(workerSagas.getAuthenticationPolicy)
    );
}

/**
 * CREATE AUTHENTICATION POLICY
 */

export function* createAuthenticationPolicyRequestWatcher() {
    yield takeLatest(
        actionTypes.CREATE_AUTHENTICATION_POLICY_REQUEST,
        exceptionHandler(workerSagas.createAuthenticationPolicy)
    );
}

/**
 * UPDATE AUTHENTICATION POLICY
 */
export function* updateAuthenticationPolicyRequestWatcher() {
    yield takeLatest(
        actionTypes.UPDATE_AUTHENTICATION_POLICY_REQUEST,
        exceptionHandler(workerSagas.updateAuthenticationPolicy)
    );
}

/**
 * DELETE AUTHENTICATION POLICIES
 */

export function* deleteAuthenticationPoliciesRequestWatcher() {
    yield takeLatest(
        actionTypes.DELETE_AUTHENTICATION_POLICIES_REQUEST,
        exceptionHandler(workerSagas.deleteAuthenticationPolicies)
    );
}

/**
 * DELETE MEMBER FROM AUTHENTICATION POLICY
 */

export function* deleteMemberFromAuthenticationPolicyRequestWatcher() {
    yield takeLatest(
        actionTypes.DELETE_MEMBER_FROM_AUTHENTICATION_POLICY_REQUEST,
        exceptionHandler(workerSagas.deleteMemberFromAuthenticationPolicy)
    );
}

/**
 * GET IP RESTRICTIONS
 */

export function* getIpRestrictionsRequestWatcher() {
    yield takeLatest(
        actionTypes.GET_IP_RESTRICTIONS_REQUEST,
        exceptionHandler(workerSagas.getIpRestrictions)
    );
}


export function* securitySaga() {
  yield all([
      getSecurityMetricsRequestWatcher(),
        enforce2FARequestWatcher(),
        stopEnforce2FARequestWatcher(),
        enforceSSORequestWatcher(),
        stopEnforceSSORequestWatcher(),
        getAllAuthenticationPoliciesRequestWatcher(),
      deleteAuthenticationPoliciesRequestWatcher(),
      getAuthenticationPolicyRequestWatcher(),
      updateAuthenticationPolicyRequestWatcher(),
      createAuthenticationPolicyRequestWatcher(),
      deleteMemberFromAuthenticationPolicyRequestWatcher(),
      getIpRestrictionsRequestWatcher()

  ]);
}

import axios from "axios";

const { REACT_APP_ISLY_API_URL: URL } = process.env;

/**
 * GET SELF COMPANY
 */

export const getDashboardMetrics = () => {
  return axios({
    method: "GET",
    url: `${URL}/metrics`,
    data: null,
  });
};

import * as actionTypes from "./types";
import { ACTION_ERROR } from "../exception-handler/types";

const initialState = {
    loading: false,
    users: null,
};

export const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USERS_REQUEST:
        return {
            ...state,
            loading: true,
        }

    case actionTypes.GET_USERS_SUCCESS:
        return {
            ...state,
            loading: false,
            users: action.payload
        }

    case actionTypes.UPDATE_COMPANY_USER_REQUEST:
        return {
            ...state,
            loading: true,
        }

    case actionTypes.UPDATE_COMPANY_USER_SUCCESS:
        return {
            ...state,
            loading: false,
        }

    case actionTypes.DELETE_USER_FROM_COMPANY_REQUEST:
        return {
            ...state,
            loading: true,
        }

    case actionTypes.DELETE_USER_FROM_COMPANY_SUCCESS:
        return {
            ...state,
            loading: false,
        }


    case ACTION_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

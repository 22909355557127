import { createSelector } from "@reduxjs/toolkit";

export const loadingSelector = createSelector(
  (state) => state.auth.loading,
  (state) => state
);
export const tokenSelector = createSelector(
  (state) => state.auth.token,
  (state) => state
);

export const authUserSelector = createSelector(
  (state) => state.auth.user,
  (state) => state
);


/**
 * GET AUTH USER
 */

export const GET_AUTH_USER_REQUEST = "GET_AUTH_USER_REQUEST";
export const GET_AUTH_USER_SUCCESS = "GET_AUTH_USER_SUCCESS";

/**
 * CHECK TOKEN
 */

export const CHECK_TOKEN_REQUEST = "CHECK_TOKEN_REQUEST";
export const CHECK_TOKEN_SUCCESS = "CHECK_TOKEN_SUCCESS";
export const CHECK_TOKEN_FAIL = "CHECK_TOKEN_FAIL";

/**
 * CHECK TOKEN WITH PARAM
 */

export const CHECK_TOKEN_WITH_PARAM_REQUEST = "CHECK_TOKEN_WITH_PARAM_REQUEST";
export const CHECK_TOKEN_WITH_PARAM_SUCCESS = "CHECK_TOKEN_WITH_PARAM_SUCCESS";
export const CHECK_TOKEN_WITH_PARAM_FAIL = "CHECK_TOKEN_WITH_PARAM_FAIL";

/**
 * Mock login & logout
 */

export const MOCK_USER_LOGIN = "MOCK_USER_LOGIN";
export const MOCK_USER_LOGOUT = "MOCK_USER_LOGOUT";

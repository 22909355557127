import axios from "axios";

const { REACT_APP_ISLY_API_URL: URL } = process.env;


/**
 * GET INDUSTRIES
 */

export const getIndustries = () => {
    return axios({
        method: "GET",
        url: `${URL}/industries`,
        data: null,
    });
}
import * as actionTypes from "./types";
import { ACTION_ERROR } from "../exception-handler/types";

const initialState = {
    loading: false,
    roles: null,
    permissions: null,
};

export const roleReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ROLES_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case actionTypes.GET_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                roles: action.payload
            }

        case actionTypes.GET_PERMISSIONS_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case actionTypes.GET_PERMISSIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                permissions: action.payload
            }

        case ACTION_ERROR:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};

import { createAction } from "@reduxjs/toolkit";
import * as actionTypes from "./types";

/**
 * GET Dashboard Metrics
 */

export const getDashboardMetricsRequest = createAction(
  actionTypes.GET_DASHBOARD_METRICS_REQUEST
);
export const getDashboardMetricsSuccess = createAction(
  actionTypes.GET_DASHBOARD_METRICS_SUCCESS
);

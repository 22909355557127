import React from "react";
import {Button} from "antd";
import classNames from "classnames";
import {Link, useHistory, useLocation} from "react-router-dom";

import { checkIsActive } from "utils";
import UserMenu from "./user-menu/UserMenu";

import { ReactComponent as UpgradeIcon } from "assets/UpgradeIcon.svg";

import styles from "./styles.module.scss";
import HelpButton from "./helpbutton/HelpButton";

const HeaderNav = ({ user }) => {
  const location = useLocation();

  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? styles.active : null;
  };

  const history = useHistory();



  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.menuContainer}>
          <Link
            className={classNames(
              styles.menuItem,
              getMenuItemActive("/dashboard")
            )}
            to="/dashboard"
          >
            Dashboard
          </Link>

          <Link
            className={classNames(
              styles.menuItem,
              getMenuItemActive("/organization")
            )}
            to="/organization"
          >
            Organization
          </Link>

          <Link
              className={classNames(
                  styles.menuItem,
                  getMenuItemActive("/products")
              )}
              to="/products"
          >
            Products
          </Link>

          <Link
              className={classNames(
                  styles.menuItem,
                  getMenuItemActive("/security")
              )}
              to="/security"
          >
            Security
          </Link>
          <Link
              className={classNames(
                  styles.menuItem,
                  getMenuItemActive("/billings")
              )}
              to="/billings"
          >
            Billings
          </Link>
          <Link
              className={classNames(
                  styles.menuItem,
                  getMenuItemActive("/settings")
              )}
              to="/settings"
          >
            Settings
          </Link>
        </div>
      </div>
      <div className={styles.toolbarContainer}>
        <Button icon={<UpgradeIcon />} className={styles.upgradeButton} onClick={()=> history.push("/billings/plans")}>
          Upgrade Now
        </Button>
        <div className={styles.actionsContainer}>
          <div className={styles.actionButton}>
            <HelpButton />
          </div>
          <UserMenu user={user} />
        </div>
      </div>

    </div>
  );
};

export default HeaderNav;

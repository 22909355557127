import React from "react";

import { HeaderNav, MobileHeader } from "./components";

import styles from "./styles.module.scss";
import { shallowEqual, useSelector } from "react-redux";
import { authUserSelector } from "../../redux/modules/auth/selectors";
import { ReactComponent as AppSwitcherIcon } from "assets/rf-icon-app-switcher.svg";
import AppSwitcher from "./components/header/appswitcher/AppSwitcher";
import {Toaster} from "sonner";

const IslyLayout = ({ children }) => {
  const user = useSelector(authUserSelector, shallowEqual);
  return (
    <div className={styles.container}>
        <Toaster position="top-right"  richColors expand={true} closeButton />
      <div className={styles.headerContainer}>
          <div className={styles.AppSwitcher}>
              <AppSwitcher />
          </div>
        <div className={styles.logo}><img src={"/logo.png"} /> | Admin</div>
        <div className={styles.header}>
          <HeaderNav user={user} />
        </div>
        <div className={styles.mobileHeader}>
          <MobileHeader />
        </div>
      </div>
      <div className={styles.contentContainer}>{children}</div>
    </div>
  );
};

export default IslyLayout;

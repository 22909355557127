import React, { useState, useEffect, useRef } from "react";
import { Divider } from "antd";
import { BiLogOut } from "react-icons/bi";
import {useDispatch} from "react-redux";
import { useLocation, Link } from "react-router-dom";
import classNames from "classnames";

import { mockUserLogout } from "redux/modules/auth/actions";

import { ReactComponent as ArrowUpRight } from "assets/ArrowUpRight.svg";
import styles from "./styles.module.scss";



const UserMenu = ({ user }) => {
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const getFirstLetters = (string) => {
    return string?.split(' ').shift().charAt(0).toUpperCase() +
        string?.split(' ').pop().charAt(0).toUpperCase();
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const logoutHandler = () => {
    dispatch(mockUserLogout());
  };

  return (
    <div className={styles.container}>
      <div
        style={{ position: "relative" }}
        ref={wrapperRef}
        onClick={() => setIsMenuOpen((prev) => !prev)}
      >
        <div
          ref={wrapperRef}
          className={classNames(styles.userAvatarContainer, {
            [styles.userAvatarContainerActive]: isMenuOpen,
          })}
        >
          {user?.profile_photo ?
              <img src={user?.profile_photo} className={styles.photo} /> :
              getFirstLetters(user?.name)}
        </div>
        <div
          ref={wrapperRef}
          className={classNames(styles.menuContainer, {
            [styles.menuContainerOpen]: isMenuOpen,
          })}
        >
          <div className={styles.userInfoContainer}>
            <div className={styles.userAvatar}>
              {user?.profile_photo ?
                  <img src={user?.profile_photo} className={styles.photo} /> :
                  getFirstLetters(user?.name)}
              <div className={styles.badge} />
            </div>
            <div className={styles.userInfo}>
              <div className={styles.userName}>{user?.name.substring(0,20)}</div>
              <div className={styles.userEmail}>{user?.email.substring(0,23)}</div>
            </div>
          </div>
          <Divider className={styles.divider} />
          <Link to="/profile-settings" className={styles.menuItem}>
            <div className={styles.menuInfo}>
              <ArrowUpRight />
              <div>Profile Settings</div>
            </div>
            <div className={styles.shortcut}> ⌘K-&gt;P </div>
          </Link>
          <Divider className={styles.divider} />
          <div className={styles.menuItem} onClick={logoutHandler}>
            <div className={styles.menuInfo}>
              <BiLogOut className={styles.logoutIcon} />
              Sign out
            </div>
            <div className={styles.shortcut}> ⌘L </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMenu;

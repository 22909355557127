import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { persistStore } from "redux-persist";

import { rootReducer } from "../reducer";
import { rootSaga } from "../saga";

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];

const { NODE_ENV } = process.env;

if (NODE_ENV === "development") {
  const { createLogger } = require("redux-logger");

  middleware.push(createLogger({ collapsed: true }));
}

export const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: NODE_ENV !== "production",
});

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

import React, { lazy, Suspense } from "react";
import axios from "axios";
import { useSelector, shallowEqual } from "react-redux";
import { Redirect, Switch, Route } from "react-router-dom";

import { tokenSelector } from "redux/modules/auth/selectors";

const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const Organization = lazy(() => import("./pages/organization/Organization"));
const Products = lazy(() => import("./pages/products/Products"));
const Security = lazy(() => import("./pages/security/Security"));
const Billings = lazy(() => import("./pages/billings/Billings"));
const Settings = lazy(() => import("./pages/settings/Settings"));
const ExceptionsPage = lazy(() => import("./pages/exceptions/ExceptionsPage"));


const AppRoutes = () => {
  const authToken = useSelector(tokenSelector, shallowEqual);

  axios.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;

  return (
    <Suspense fallback={<div />}>
      <Switch>
        {<Redirect exact from="/" to="/dashboard" />}
        <Route path="/dashboard" component={Dashboard} />
        <Route path={"/organization"} component={Organization} />
        <Route path={"/products"} component={Products} />
          <Route path={"/security"} component={Security} />
          <Route path={"/billings"} component={Billings} />
          <Route path={"/settings"} component={Settings} />
        <Route path="*" component={ExceptionsPage} />
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;

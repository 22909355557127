import React from "react";
import {Card, Dropdown} from "antd";
import styles from "./styles.module.scss";
import { ReactComponent as AppSwitcherIcon } from "assets/rf-icon-app-switcher.svg";

const AppSwitcher = () => {

    const menu = () => {
        return (
            <>
                <Card className={styles.AppSwitcherCard}>
                    <h1 className={styles.SwitchToButton}>Switch to</h1>
                    <div className={styles.AppSwitcherCardItem}>
                        <a href="https://isly.io" target="_blank" rel="noopener noreferrer">
                            <div className={styles.AppSwitcherCardItemIcon}>
                                <img src="https://interface-design.co.uk/favicon-32x32.png"  />
                            </div>
                            <div className={styles.AppSwitcherCardItemText}>
                                <h1 className={styles.AppSwitcherCardItemTextTitle}>Isly</h1>
                                <p className={styles.AppSwitcherCardItemTextDescription}>Analysis your 3rd Party Apps</p>
                            </div>
                        </a>
                    </div>

                    <div className={styles.AppSwitcherCardItem}>
                        <a href="https://isly.io" target="_blank" rel="noopener noreferrer">
                            <div className={styles.AppSwitcherCardItemIcon}>
                                <img src="https://cdn.dribbble.com/assets/favicon-b38525134603b9513174ec887944bde1a869eb6cd414f4d640ee48ab2a15a26b.ico"  />
                            </div>
                            <div className={styles.AppSwitcherCardItemText}>
                                <h1 className={styles.AppSwitcherCardItemTextTitle}>Safari</h1>
                                <p className={styles.AppSwitcherCardItemTextDescription}>Manage your Attack Surface</p>
                            </div>
                        </a>
                    </div>

                    <div className={styles.AppSwitcherCardItem}>
                        <a href="https://isly.io" target="_blank" rel="noopener noreferrer">
                            <div className={styles.AppSwitcherCardItemIcon}>
                                <img src="https://www.vecteezy.com/favicon.ico"  />
                            </div>
                            <div className={styles.AppSwitcherCardItemText}>
                                <h1 className={styles.AppSwitcherCardItemTextTitle}>Nova</h1>
                                <p className={styles.AppSwitcherCardItemTextDescription}>Build smart questionnaires </p>
                            </div>
                        </a>
                    </div>


                </Card>
            </>
        );
    };

    return (
        <Dropdown overlay={menu()} trigger={['click']}>
            <a onClick={e => e.preventDefault()}>
                <AppSwitcherIcon className={styles.AppSwitcherIcon}  />
            </a>
        </Dropdown>
    );

};

export default AppSwitcher;

import { combineReducers } from "redux";

import { authReducer } from "../modules/auth/reducers";
import { dashboardMetricsReducer } from "../modules/dashboard/reducers";
import { securityReducer } from "../modules/security/reducers";
import {companyReducer} from "../modules/company/reducers";
import {organizationReducer} from "../modules/organization/reducers";
import {roleReducer} from "../modules/role/reducers";
import { industriesReducer } from "../modules/industries/reducers";

export const rootReducer = combineReducers({
  auth: authReducer,
  dashboardMetrics: dashboardMetricsReducer,
  security: securityReducer,
  company: companyReducer,
  organization: organizationReducer,
  role: roleReducer,
  industries: industriesReducer,

});
import React, { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import SplashScreen from '../_isly/components/splash-screen/SplashScreen';

import "../utils/i18nextInit";

import MainRouter from "./MainRouter";

const App = ({ store, persistor }) => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <Suspense fallback={<SplashScreen />}>
            <MainRouter />
          </Suspense>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default SplashScreen(App);
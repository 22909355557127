import { all, fork } from "redux-saga/effects";

import { authSaga } from "../modules/auth/watcherSagas";
import { dashboardMetricsSaga } from "redux/modules/dashboard/watcherSagas";
import { securitySaga } from "redux/modules/security/watcherSagas";
import { companySaga } from "redux/modules/company/watcherSagas";
import { organizationSaga } from "redux/modules/organization/watcherSagas";
import { roleSaga } from "redux/modules/role/watcherSagas";
import { industriesSaga } from "redux/modules/industries/watcherSagas";


export function* rootSaga() {
  yield all([
    fork(authSaga),
    fork(dashboardMetricsSaga),
    fork(securitySaga),
    fork(companySaga),
    fork(organizationSaga),
    fork(roleSaga),
    fork(industriesSaga),

  ]);
}

import { createAction } from "@reduxjs/toolkit";
import * as actionTypes from "./types";


/**
 * GET INDUSTRIES
 */

export const getIndustriesRequest = createAction(
    actionTypes.GET_INDUSTRIES_REQUEST
);
export const getIndustriesSuccess = createAction(
    actionTypes.GET_INDUSTRIES_SUCCESS
);

import { createAction } from "@reduxjs/toolkit";
import * as actionTypes from "./types";


/**
 * GET COMPANY
 */

export const getCompanyRequest = createAction(
    actionTypes.GET_COMPANY_REQUEST
);
export const getCompanySuccess = createAction(
    actionTypes.GET_COMPANY_SUCCESS
);

/**
 * UPDATE COMPANY
 */

export const updateCompanyRequest = createAction(
    actionTypes.UPDATE_COMPANY_REQUEST
);

export const updateCompanySuccess = createAction(
    actionTypes.UPDATE_COMPANY_SUCCESS
);


/**
 * GET API KEYS
 */

export const getApiKeysRequest = createAction(
    actionTypes.GET_API_KEYS_REQUEST
);

export const getApiKeysSuccess = createAction(
    actionTypes.GET_API_KEYS_SUCCESS
);


/**
 * UPDATE API KEY
 */

export const updateApiKeyRequest = createAction(
    actionTypes.UPDATE_API_KEY_REQUEST
);

export const updateApiKeySuccess = createAction(
    actionTypes.UPDATE_API_KEY_SUCCESS
);


/**
 * DELETE API KEY
 */

export const deleteApiKeyRequest = createAction(
    actionTypes.DELETE_API_KEY_REQUEST
);

export const deleteApiKeySuccess = createAction(
    actionTypes.DELETE_API_KEY_SUCCESS
);

/**
 * CREATE API KEY
 */

export const createApiKeyRequest = createAction(
    actionTypes.CREATE_API_KEY_REQUEST
);

export const createApiKeySuccess = createAction(
    actionTypes.CREATE_API_KEY_SUCCESS
);


/**
 * GET ACTIVE NOTIFICATION CHANNELS
 */

export const getActiveNotificationChannelsRequest = createAction(
    actionTypes.GET_ACTIVE_NOTIFICATION_CHANNELS_REQUEST
);

export const getActiveNotificationChannelsSuccess = createAction(
    actionTypes.GET_ACTIVE_NOTIFICATION_CHANNELS_SUCCESS
);


/**
 * UPDATE NOTIFICATION CHANNEL
 */

export const updateNotificationChannelRequest = createAction(
    actionTypes.UPDATE_NOTIFICATION_CHANNEL_REQUEST
);

export const updateNotificationChannelSuccess = createAction(
    actionTypes.UPDATE_NOTIFICATION_CHANNEL_SUCCESS
);


/**
 * DELETE NOTIFICATION CHANNEL
 */

export const deleteNotificationChannelRequest = createAction(
    actionTypes.DELETE_NOTIFICATION_CHANNEL_REQUEST
);

export const deleteNotificationChannelSuccess = createAction(
    actionTypes.DELETE_NOTIFICATION_CHANNEL_SUCCESS
);


/**
 * GET ALL NOTIFICATION CHANNELS INFORMATION
 */

export const getAllNotificationChannelsInformationRequest = createAction(
    actionTypes.GET_ALL_NOTIFICATION_CHANNELS_INFORMATION_REQUEST
);

export const getAllNotificationChannelsInformationSuccess = createAction(
    actionTypes.GET_ALL_NOTIFICATION_CHANNELS_INFORMATION_SUCCESS
);

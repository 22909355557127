import axios from "axios";

const { REACT_APP_ISLY_API_URL: URL } = process.env;


/**
 * GET COMPANY
 */

export const getCompany = () => {
  return axios({
    method: "GET",
    url: `${URL}/company`,
    data: null,
  });
};

/**
 * UPDATE COMPANY
 */

export const updateCompany = (payload) => {
return axios({
    method: "PUT",
    url: `${URL}/company`,
    data: payload,
    headers: {
        "Content-Type": payload.logo ? "multipart/form-data" : "application/json"
    },
  });
}


/**
 * GET API KEYS
 */

export const getApiKeys = () => {
return axios({
    method: "GET",
    url: `${URL}/api-keys`,
    data: null,
  });
}


/**
 * UPDATE API KEY
 */

export const updateApiKey = (payload) => {
return axios({
    method: "PUT",
    url: `${URL}/api-keys/${payload.id}`,
    data: payload,
  });
}


/**
 * DELETE API KEY
 */

export const deleteApiKey = (payload) => {
return axios({
    method: "DELETE",
    url: `${URL}/api-keys/${payload.id}`,
    data: null,
  });
}

/**
 * CREATE API KEY
 */

export const createApiKey = (payload) => {
return axios({
    method: "POST",
    url: `${URL}/api-keys`,
    data: payload,
  });
}

/**
 * GET ACTIVE NOTIFICATION CHANNELS
 */

export const getActiveNotificationChannels = () => {
return axios({
    method: "GET",
    url: `${URL}/notification-channels`,
    data: null,
  });
}

/**
 * UPDATE NOTIFICATION CHANNEL
 */

export const updateNotificationChannel = (payload) => {
return axios({
    method: "PUT",
    url: `${URL}/notification-channels/${payload.id}`,
    data: payload,
  });
}

/**
 * DELETE NOTIFICATION CHANNEL
 */

export const deleteNotificationChannel = (payload) => {
return axios({
    method: "DELETE",
    url: `${URL}/notification-channels/${payload}`,
    data: null,
  });
}


/**
 * GET ALL NOTIFICATION CHANNELS INFORMATION
 */

export const getAllNotificationChannelsInformation = () => {
return axios({
    method: "GET",
    url: `${URL}/notification-channels/get-info`,
    data: null,
  });
}
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import * as actionTypes from "./types";
import { ACTION_ERROR } from "../exception-handler/types";

const initialState = {
  token: null,
  loading: false,
  registerToken: null,
  twoFALoading: false,
  isLoggedIn: false,
  user: null,
  resendLoading: false,
};

const persistConfig = {
  storage,
  key: "islyApp-auth",
  whitelist: ["token", "registerToken", "isLoggedIn", "user"],
};

export const authReducer = persistReducer(
  persistConfig,
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.MOCK_USER_LOGIN:
        return {
          ...state,
          token: "mock_login",
        };
      case actionTypes.MOCK_USER_LOGOUT:
        return {
          ...state,
          token: null,
          user: null,
          isLoggedIn: false,
          registerToken: null,
        };

      case actionTypes.GET_AUTH_USER_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.GET_AUTH_USER_SUCCESS:
        return {
          ...state,
          loading: false,
          user: action.payload,
        };

      case actionTypes.CHECK_TOKEN_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.CHECK_TOKEN_SUCCESS:
        return {
          ...state,
          loading: false,
        };

      case actionTypes.CHECK_TOKEN_WITH_PARAM_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case actionTypes.CHECK_TOKEN_WITH_PARAM_SUCCESS:
        const {
          payload: { user },
        } = action;
        return {
          ...state,
          loading: false,
          token: action.payload.token,
          user,
          isLoggedIn: true,
        };

      case actionTypes.CHECK_TOKEN_FAIL:
        return {
          ...state,
          loading: false,
          token: null,
          user: null,
          isLoggedIn: false,
          registerToken: null,
        };

      case actionTypes.CHECK_TOKEN_WITH_PARAM_FAIL:
        return {
          ...state,
          loading: false,
          token: null,
          user: null,
          isLoggedIn: false,
          registerToken: null,
        };
      case ACTION_ERROR:
        return {
          ...state,
          loading: false,
          resendLoading: false,
        };
      default:
        return state;
    }
  }
);

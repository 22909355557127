import axios from "axios";

const { REACT_APP_ISLY_API_URL: URL } = process.env;

/**
 * GET ROLES
 */

export const getRoles = () => {
    return axios({
        method: "GET",
        url: `${URL}/roles`,
        data: null,
    });
}

/**
 * GET PERMISSIONS
 */

export const getPermissions = () => {
    return axios({
        method: "GET",
        url: `${URL}/permissions`,
        data: null,
    });
}
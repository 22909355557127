import { call, put } from "redux-saga/effects";

import * as api from "./api";
import * as actions from "./actions";

/**
 * GET SELF COMPANY
 */

export function* getDashboardMetrics() {
  const response = yield call(api.getDashboardMetrics);

  yield put(actions.getDashboardMetricsSuccess(response));
}

/**
 * GET COMPANY
 */

export const GET_COMPANY_REQUEST = "GET_COMPANY_REQUEST";
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";

/**
 * UPDATE COMPANY
 */

export const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";


/**
 * GET API KEYS
 */

export const GET_API_KEYS_REQUEST = "GET_API_KEYS_REQUEST";

export const GET_API_KEYS_SUCCESS = "GET_API_KEYS_SUCCESS";

/**
 * UPDATE API KEY
 */

export const UPDATE_API_KEY_REQUEST = "UPDATE_API_KEY_REQUEST";

export const UPDATE_API_KEY_SUCCESS = "UPDATE_API_KEY_SUCCESS";

/**
 * DELETE API KEY
 */

export const DELETE_API_KEY_REQUEST = "DELETE_API_KEY_REQUEST";

export const DELETE_API_KEY_SUCCESS = "DELETE_API_KEY_SUCCESS";


/**
 * CREATE API KEY
 */

export const CREATE_API_KEY_REQUEST = "CREATE_API_KEY_REQUEST";
export const CREATE_API_KEY_SUCCESS = "CREATE_API_KEY_SUCCESS";


/**
 * GET ACTIVE NOTIFICATION CHANNEL
 */

export const GET_ACTIVE_NOTIFICATION_CHANNELS_REQUEST = "GET_ACTIVE_NOTIFICATION_CHANNELS_REQUEST";
export const GET_ACTIVE_NOTIFICATION_CHANNELS_SUCCESS = "GET_ACTIVE_NOTIFICATION_CHANNELS_SUCCESS";

/**
 * UPDATE NOTIFICATION CHANNEL
 */

export const UPDATE_NOTIFICATION_CHANNEL_REQUEST = "UPDATE_NOTIFICATION_CHANNEL_REQUEST";
export const UPDATE_NOTIFICATION_CHANNEL_SUCCESS = "UPDATE_NOTIFICATION_CHANNEL_SUCCESS";

/**
 * DELETE NOTIFICATION CHANNEL
 */

export const DELETE_NOTIFICATION_CHANNEL_REQUEST = "DELETE_NOTIFICATION_CHANNEL_REQUEST";
export const DELETE_NOTIFICATION_CHANNEL_SUCCESS = "DELETE_NOTIFICATION_CHANNEL_SUCCESS";

/**
 * GET ALL NOTIFICATION CHANNELS INFORMATION
 */

export const GET_ALL_NOTIFICATION_CHANNELS_INFORMATION_REQUEST = "GET_ALL_NOTIFICATION_CHANNELS_INFORMATION_REQUEST";

export const GET_ALL_NOTIFICATION_CHANNELS_INFORMATION_SUCCESS = "GET_ALL_NOTIFICATION_CHANNELS_INFORMATION_SUCCESS";

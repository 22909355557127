import { call, put } from "redux-saga/effects";

import * as api from "./api";
import * as actions from "./actions";

/**
 * GET AUTH USER
 */

export function* getAuthUser() {
  const response = yield call(api.getAuthUser);

  const {
    data: { data },
  } = response;

  yield put(actions.getAuthUserSuccess(data));
}


/**
 * CHECK TOKEN
 */

export function* checkToken() {
  try {
    const response = yield call(api.checkToken);

    yield put(actions.checkTokenSuccess(response));
  } catch (error) {
    yield put(actions.checkTokenFail(error));
  }
}


/**
 * CHECK TOKEN
 */

export function* checkTokenWithParam(action) {
  try {
    const response = yield call(api.checkTokenWithParams, action.payload);
    const {
      data: { data },
    } = response;

    if (data === null) {
      yield put(actions.checkTokenWithParamFail("Token is invalid"));
      return;
    }

    yield put(actions.checkTokenWithParamSuccess({ token: action.payload, user: data }));

  } catch (error) {
    yield put(actions.checkTokenWithParamFail(error));
    window.location = "https://deepensive.com"
  }
}


import { call, put } from "redux-saga/effects";
import {toast} from "sonner";

import * as api from "./api";
import * as actions from "./actions";

/**
 * GET COMPANY
 */

export function* getCompany() {
  const response = yield call(api.getCompany);
  const {
    data: { data },
  } = response;

  yield put(actions.getCompanySuccess(data));
}

/**
 * UPDATE COMPANY
 */

export function* updateCompany({ payload }) {
    const response = yield call(api.updateCompany, payload);
    const {
        data: { data },
    } = response;

    yield put(actions.updateCompanySuccess(data));
    yield toast.success(response.data.message);
}


/**
 * GET API KEYS
 */

export function* getApiKeys() {
    const response = yield call(api.getApiKeys);
    const {
        data: { data },
    } = response;

    yield put(actions.getApiKeysSuccess(data));
}

/**
 * UPDATE API KEY
 */

export function* updateApiKey({ payload }) {
    const response = yield call(api.updateApiKey, payload);

    yield getApiKeys();
    yield toast.success(response.data.message);
}

/**
 * DELETE API KEY
 */

export function* deleteApiKey({ payload }) {
    const response = yield call(api.deleteApiKey, payload);

    yield getApiKeys();
    yield toast.success(response.data.message);
}

/**
 * CREATE API KEY
 */

export function* createApiKey({ payload }) {
    const response = yield call(api.createApiKey, payload);

    yield put(actions.createApiKeySuccess(response.data.data));
    yield getApiKeys();
    yield toast.success(response.data.message);
}


/**
 * GET ACTIVE NOTIFICATION CHANNELS
 */

export function* getActiveNotificationChannels() {
    const response = yield call(api.getActiveNotificationChannels);
    const {
        data: { data },
    } = response;

    yield put(actions.getActiveNotificationChannelsSuccess(data));
}

/**
 * UPDATE NOTIFICATION CHANNEL
 */

export function* updateNotificationChannel({ payload }) {
    const response = yield call(api.updateNotificationChannel, payload);

    yield getActiveNotificationChannels();
    yield toast.success(response.data.message);
}

/**
 * DELETE NOTIFICATION CHANNEL
 */

export function* deleteNotificationChannel({ payload }) {
    const response = yield call(api.deleteNotificationChannel, payload);

    yield getActiveNotificationChannels();
    yield toast.success(response.data.message);
}

/**
 * GET ALL NOTIFICATION CHANNELS INFORMATION
 */

export function* getAllNotificationChannelsInformation() {
    const response = yield call(api.getAllNotificationChannelsInformation);
    const {
        data: { data },
    } = response;

    yield put(actions.getAllNotificationChannelsInformationSuccess(data));
}
import { all, takeLatest } from "redux-saga/effects";
import { exceptionHandler } from "../exception-handler";

import * as actionTypes from "./types";
import * as workerSagas from "./workerSagas";

/**
 * GET AUTH USER
 */

export function* getAuthUserRequestWatcher() {
  yield takeLatest(
    actionTypes.GET_AUTH_USER_REQUEST,
    exceptionHandler(workerSagas.getAuthUser)
  );
}

/**
 * UPDATE USER
 */

export function* checkTokenRequestWatcher() {
  yield takeLatest(
      actionTypes.CHECK_TOKEN_REQUEST,
      workerSagas.checkToken
  );
}

export function* checkTokenRequestWithParamWatcher() {
  yield takeLatest(
      actionTypes.CHECK_TOKEN_WITH_PARAM_REQUEST,
      workerSagas.checkTokenWithParam
  );
}

export function* authSaga() {
  yield all([
    getAuthUserRequestWatcher(),
    checkTokenRequestWatcher(),
    checkTokenRequestWithParamWatcher(),
  ]);
}

import { call, put } from "redux-saga/effects";

import * as api from "./api";
import * as actions from "./actions";

/**
 * GET ROLES
 */
export function* getRoles() {
    const response = yield call(api.getRoles);
    const {
        data: { data },
    } = response;

    yield put(actions.getRolesSuccess(data));
}

/**
 * GET PERMISSIONS
 */

export function* getPermissions() {
    const response = yield call(api.getPermissions);
    const {
        data: { data },
    } = response;

    yield put(actions.getPermissionsSuccess(data));
}
import { all, takeLatest } from "redux-saga/effects";
import { exceptionHandler } from "../exception-handler";

import * as actionTypes from "./types";
import * as workerSagas from "./workerSagas";

export function* getDashboardMetricsRequestWatcher() {
  yield takeLatest(
    actionTypes.GET_DASHBOARD_METRICS_REQUEST,
    exceptionHandler(workerSagas.getDashboardMetrics)
  );
}


export function* dashboardMetricsSaga() {
  yield all([
    getDashboardMetricsRequestWatcher(),
  ]);
}
